var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-2"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/warehouses"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_warehouse")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_warehouse")) + " ")])], 1)]), _c('div', {
    staticClass: "bg-white rounded-lg p-6 pt-2"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "placeholder": _vm.$t('warehouse_name'),
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.options,
      "label": "name"
    },
    on: {
      "input": _vm.setSelectedContry
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1)])]), _c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6 pt-10"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "currency"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("currency")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.currency,
      expression: "formData.currency"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "currency",
      "placeholder": _vm.$t('warehouse_currency'),
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.currency
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "currency", $event.target.value);
      }
    }
  })])]), _c('div')]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }