<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/warehouses"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_warehouse") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_warehouse") }}
          </div>
        </div>
      </div>
      <div class="bg-white rounded-lg p-6 pt-2">
        <div class="grid lg:grid-cols-2 gap-6">
          <div
            class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="name" class="bg-white text-gray-700 px-1">
                  {{ $t("name") }}
                  <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <input
                id="name"
                :placeholder="$t('warehouse_name')"
                tabindex="0"
                type="text"
                v-model="formData.name"
                class="py-2 text-xs px-2 outline-none block h-full w-full"
              />
            </p>
          </div>
          <div
            class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="country" class="bg-white text-gray-700 px-1">{{
                  $t("country")
                }}</label>
              </p>
            </div>
            <p>
              <v-select
                @input="setSelectedContry"
                v-model="formData.country"
                :options="options"
                label="name"
              >
                <template slot="option" slot-scope="option">
                  <img class="flag-img" :src="$f.getCountryCode(option.code)" />
                  {{ option.name }}
                </template>
              </v-select>
            </p>
          </div>
          <!--   <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="zone" class="bg-white text-gray-700 px-1"
                        >{{$t('zone')}}<span :class="$colors.required">
                          *</span
                        ></label
                      >
                    </p>
                  </div>
                  <div
                    id="city"
                    class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
                  >
                    <div class="w-full">
                      <div class="flex flex-col  relative">
                        <div class="items">
                            <label v-for="(item,index) in zones" :key="index" class="inline-flex items-center">
                               <input :id="item._id" @change='getRowDetail(item._id)' type="checkbox" :class="'rowCheckboxZone-'+item._id" class="form-checkbox h-5 w-5 text-green-600"><span class="ml-2 font-medium text-gray-800">{{item.name}}</span>
                            </label>
                        </div>
                       
                      </div>
                    </div>
                  </div>-->
        </div>
        <div class="grid lg:grid-cols-2 gap-6 pt-10">
          <div
            class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
          >
            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
              <p>
                <label for="currency" class="bg-white text-gray-700 px-1">
                  {{ $t("currency") }}
                  <span :class="$colors.required">*</span></label
                >
              </p>
            </div>
            <p>
              <input
                id="currency"
                :placeholder="$t('warehouse_currency')"
                tabindex="0"
                type="text"
                v-model="formData.currency"
                class="py-2 text-xs px-2 outline-none block h-full w-full"
              />
            </p>
          </div>
          <div></div>
        </div>
        <div class="mt-6 pt-3 flex justify-center">
          <button
            @click="save"
            class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
      },
      options: this.$countries,
      selectContry: "",
      zones: [],
    };
  },
  async mounted() {
    await this.getUser();
    await this.getZones();
    this.dataToEdit();
  },
  methods: {
    setSelectedContry(contry) {
      if (contry) {
        this.formData.country = contry.name;
        this.formData.countryName = contry.name;
        this.selectContry = contry.code;
      }
    },
    async save() {
      if (this.formData._id) {
        this.formData.country = this.selectContry;
        if (this.checkData()) {
          this.formData.flag = this.$f.getCountryCode(this.selectContry);
          const data = await this.$server.update("warehouses", this.formData);
          if (data._id) {
            this.formData.country = this.$f.getCountryNameByCode(
              this.options,
              this.selectContry
            );
            alert(this.$t("warehouse_updated"), "success");
          }
        } else {
          alert(this.$t("all_required_field"), "warning");
        }
      } else {
        if (this.checkData()) {
          this.formData.country = this.selectContry;
          const resW = await this.$server.get("warehouses", {
            country: this.selectContry,
          });

          if (resW.content != "warehouses not found") {
            this.formData.country = this.$f.getCountryNameByCode(
              this.options,
              this.selectContry
            );
            alert(this.$t("warehouse_already_exist_incontry"), "warning");
            return false;
          }
          this.formData.flag = this.$f.getCountryCode(this.selectContry);
          const data = await this.$server.create("warehouses", this.formData);
          if (data._id) {
            alert(this.$t("warehouse_created"), "success");
            this.formData.name = this.formData.country = this.selectContry = "";
          }
        } else {
          alert(this.$t("all_required_field"), "warning");
        }
      }
      // fetch and populate vuex store after save
      this.$store.dispatch("app/geAllWarehouses");
    },
    emtyTabId() {
      let columns = document.querySelectorAll(".form-checkbox");
      columns.forEach((column) => {
        column.checked = false;
      });
      delete formData.zone;
    },
    async getRowDetail(id) {
      // let rows = this.selectedRows;

      let columns = document.querySelectorAll(".form-checkbox");
      let element = document.querySelector(".rowCheckboxZone-" + id);
      columns.forEach((column) => {
        column.checked = false;
      });
      element.checked = true;
      this.formData.zone = id;
    },
    checkData() {
      if (this.formData.name && this.formData.country) {
        return true;
      } else {
        return false;
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      //
    },
    async getZones() {
      const zones = await this.$server.search("zones", { limit: 100 });
      if (zones.content) {
        this.zones = zones.content.results;
      }
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("warehouses", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.selectContry = this.formData.country;
        this.formData.country = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country
        );
        //  let columns = document.querySelectorAll('.form-checkbox');
        // let element = document.querySelector('.rowCheckboxZone-'+editData.content.zone);
        // columns.forEach(column => {column.checked = false});
        // element.checked = true;
      }
    },
    /*************************************************************** */
    /**************************************************************/
  },
};
</script>

<style>
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.items {
  display: flex;
  flex-direction: column;
  padding: 10px;
  counter-reset: done-items undone-items;
}
/* .items input {
    display: block;
    height: 53px;
    margin: 0 0 -53px -9999px;
    outline: none;
    counter-increment: done-items;
} */
.items input:checked + label {
  -webkit-animation: done 0.5s;
  animation: done 0.5s;
}
.items label {
  position: relative;
  display: block;
  position: relative;
  padding: 15px 0 15px 15px;
  border-top: 1px dashed #e2e8f0;
  cursor: pointer;
  -webkit-animation: undone 0.5s;
  animation: undone 0.5s;
}
.items label:first-child {
  border-top: inherit;
}
.items label i {
  display: block;
  position: absolute;
  top: 11px;
  left: 10px;
}
.flag-img {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: text-top;
  line-height: 16px;
  margin-right: 5px;
}
.tag_cities {
  width: 100%;
  max-width: 100%;
}
.vs__dropdown-toggle {
  border: none;
}
.tag_cities .tag {
  background: rgb(49 130 206 / 16%);
  border-radius: 20px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 28px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  padding-right: 0;
}
.vs-chip--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10px;
}
.vs-chip--close {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tag_cities .tag [data-role="remove"]::after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}
.tag_cities .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.top-100 {
  top: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.max-h-select {
  max-height: 300px;
}
</style>
